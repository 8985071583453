<template>
  <div>
    <!-- ..Mapeado.. -->
    <div v-if="setDataCoordsAndImg != null">
      <div
        style="background-color: white"
        v-for="(item, i) in setDataCoordsAndImg"
        :key="i"
      >
        <div>
          <coordinate-mapper
            :id="item.front.id"
            :img="item.front.img"
            :coords="item.front.coords"
            :real_estate_group_id="development.real_estate_group_id"
          >
          </coordinate-mapper>
          <coordinate-mapper
            :id="item.plane.id"
            :img="item.plane.img"
            :coords="item.plane.coords"
            :real_estate_group_id="development.real_estate_group_id"
          >
          </coordinate-mapper>
        </div>
      </div>
    </div>
    <!-- Termina Mapeado -->
  </div>
</template>
<script>
import { generateQuote } from "@/pdf/advanced-quoter/main.js";
export default {
  name: "GenerateMappingWithPdf",
  components: {
    CoordinateMapper: () => import("./CoordinateMapper.vue")
  },
  props: {
    dataMap: {
      Object: Object,
      default: null
    },
    properties: {
      Array: Array,
      default: null
    },
    packedPriceRule: {
      Object: Object,
      default: null
    },
    purchaseScheme: {
      Object: Object,
      default: null
    },
    development: {
      Object: Object,
      default: null
    },
    name: {
      String: String,
      default: ""
    },
    contactSelected: {
      Object: Object,
      default: null
    },
    enabled: {
      Boolean: Boolean,
      default: false
    },
    language: {
      String: String,
      default: "Spanish"
    }
  },
  data() {
    return {
      setDataCoordsAndImg: null,
      cuatroDatos: {},
      unitType: "offices"
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.development.unit_types) {
        this.unitType = this.development.unit_types;
      }
      const dataMapFinish = this.coordsAndImg();
      this.setDataCoordsAndImg = dataMapFinish ? dataMapFinish : null;
    },
    //Se obtienen todas las coordenadas por nivel
    planeCoordinates() {
      let coordsFloors = [];
      //Obtener niveles..
      const floors = [...new Set(this.properties.map(b => b.floor.name))];
      //Obtener todas las coordenads del plano por nivel
      floors.forEach(floor => {
        //Filtrar propiedades...
        const filter = this.properties.filter(fd => fd.floor.name === floor);
        //Mapear las coordenadas de las propiedades..
        for (const b of filter) {
          coordsFloors.push({
            floor,
            coords: [b.miscellaneous.mapping_coords],
            code: b.code
          });
        }
      });
      return coordsFloors;
    },
    //Funcion final para tener los datos listos...
    coordsAndImg() {
      const planeCoors = this.planeCoordinates();
      let exit = [];
      let count = 0;
      const real_estate_group_id = this.development.real_estate_group_id;
      planeCoors.forEach(planeCoord => {
        const find = this.dataMap?.coords_and_img.find(
          f => planeCoord.floor === f.name
        );
        const structure = {
          floor: planeCoord.floor,
          code: planeCoord.code,
          front: {
            id: "front" + count,
            img: this.dataMap?.img_front,
            coords: find?.mapping_coords ? [find.mapping_coords] : []
          },
          plane: {
            id: "plane" + count,
            img:
              find?.media?.especifications?.src === "" ||
              !find?.media?.especifications?.src
                ? real_estate_group_id === "614b74d1a7fca30c13490e7b" ||
                  real_estate_group_id === "65df671efde633d2f2489eda"
                  ? this.dataMap?.img_front
                  : find?.media?.especifications?.src
                : find?.media?.especifications?.src,
            coords: planeCoord.coords
          }
        };
        exit.push(structure);
        count++;
      });
      return exit;
    },
    async generateQuote() {
      const structure = {
        coords_map: this.setDataCoordsAndImg,
        properties: this.properties,
        packedPriceRule: this.packedPriceRule,
        purchaseScheme: this.purchaseScheme,
        dataFrontPage: this.getDataFrontPage(this.development),
        real_estate_group_id: this.development.real_estate_group_id,
        style: this.development.miscellaneous.quote.styles_pdf,
        name: this.name,
        contactSelected: this.contactSelected,
        language: this.language
      };

      generateQuote(structure, this.unitType).then(e => {
        this.$emit("getLoading", e);
      });
    },
    getDataFrontPage(dev) {
      let nameLead = this.name ? this.name : " Sin nombre";
      let nameDev = dev.name ? dev.name : "Sin nombre";
      let address = "";
      if (dev.address) {
        if (dev.address.full_address) {
          address = dev.address.full_address;
        }
        if (dev.address.city) {
          if (address !== "") {
            address += ". " + dev.address.city;
          } else {
            address = dev.address.city;
          }
        }
        if (dev.address.state) {
          if (address !== "") {
            address += ". " + dev.address.state;
          } else {
            address = dev.address.state;
          }
        }
      }
      return {
        nameLead,
        nameDev,
        address
      };
    },
    getImgs(media) {
      return {
        logo: this.getImgLogo(media),
        frontImg: this.getFrontImg(media)
      };
    },
    getImgLogo(media) {
      if (!media) return null;
      if (!media.featured_image) return null;
      if (!media.featured_image.src) return null;
      return media.featured_image.src;
    },
    getFrontImg(media) {
      if (!media) return null;
      if (!media.structure) return null;
      if (!media.structure.src) return null;
      return media.structure.src;
    }
  }
};
</script>
